.pageWrapper {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, #fec9cc 0%, #f4c9fe 50%, #b8e6ff 100%);
  padding: 50px;
}

.thankYouContainer {
  text-align: center;
  width: 690px;
  padding: 0 40px;
  margin: auto;
}

.title {
  font-size: 2em;
  margin-bottom: 10px;
}

.text {
  font-size: 1.2em;
  margin: 5px 0;
}

.textNew {
  font-size: 1.2em;
  margin: 5px 0;
  font-weight: 600;
}

.imageGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  margin-top: 20px;
}

.gridImage {
  max-width: 300px;
  height: auto;
}
